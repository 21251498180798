
import './DetailsDashboard.css';
import { ACCProjectResponse } from '../../models/ACCProjects.model'
import  { useState, useEffect, useRef } from 'react';
import ModelsHelper from './ModelsHelper'
import Progress from './Progress'
import ClipLoader from "react-spinners/ClipLoader";


type inpParams = { project: ACCProjectResponse, token: string }
export default function ModelsProvided({ project, token }: inpParams) {


  const [models, setModels] = useState([{ name: "", id: "", parentFolder: { name: "",id:"" }, createTime: "", createUserName:"", lastModifiedTime: "", version: { versionNumber: 0 },alternativeIdentifiers:{fileUrn:""}, loading:false,progress:0,LoadingTime:0,workItemId:"",failed:false,RVTVersion:""}]);
  const [modelsLoading, setModelsLoading] = useState([{ name: "", id: "", parentFolder: { name: "",id:"" }, createTime: "", createUserName:"", lastModifiedTime: "", version: { versionNumber: 0 },alternativeIdentifiers:{fileUrn:""}, loading:false,progress:0,LoadingTime:0,workItemId:"",failed:false,RVTVersion:""}]);
  const [modelLoadError,setmodelLoadError] = useState("");

  const [modelsCreated, setCreated] = useState({ Mech: false, Elec: false, Struct: false });
  const [showModal, setShowModal] = useState(false);
  const [enableButton, setEnableButton] = useState(true);


  const [MechCheck, setMechCheck] = useState(false);
  const [ElecCheck, setElecCheck] = useState(false);
  const [StructCheck, setStructCheck] = useState(false);
  const [version, setversion] = useState('2024');



  let helper = new ModelsHelper();

  const creationRequested= () => {
    setEnableButton(true)
    setShowModal(false);
    

    const pattern = /\d{3,8}[-]\d{2,5}/g
    const matches = project.name.match(pattern)

    let project_number:string =project.name
    if(matches && matches.length > 0 && project.name && project.name.match(pattern) != null){
      project_number =  matches[0]
    }
    
    helper.createModels(MechCheck,ElecCheck,StructCheck,token,project.id,version,project_number).then((resultsOfCreation)=>{
      console.log(resultsOfCreation)
      let initProgress = helper.initializeProgress(MechCheck,ElecCheck,StructCheck,version,project_number,resultsOfCreation.id)
      setModelsLoading(initProgress)
      setInterval(iterateThrougModels,30000)
      setEnableButton(false)

    })
  }

  const iterateThrougModels= async () => {
    
      let result = await helper.CheckModels(project.name,token)
      if(result){window.location.reload();}
      console.log(helper.ModelsLoading)
      setModelsLoading(helper.ModelsLoading)

     
    
  }
  


  const effectRan = useRef(false);

  useEffect(() => {

    if (!effectRan.current) {

     // helper.getPModels(token, project.name,project.id).then((models) => {
      helper.getModelsDataManagment(token, project.name,project.id).then((models) => {
      
        if(typeof models === "string"){
          setmodelLoadError(models)
        }else{
          setmodelLoadError("")
         
          setModels(models)
          setCreated(helper.populateCreatedModels(models))
          setEnableButton(false)
        }
        
        //  helper.append_Meta_Data(token,models).then((models_combined)=>{
        // setModels(models_combined)
        // })
        
      })

    }
    return () => { effectRan.current = true };
    
  }, [])


  return (
    <>
    
   
    <br/>
      <div className='ActionsHead'><a className='DetailsHeaders'>Models in ACC</a>  
      
    
      
       <button disabled={enableButton}
        className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Create Models
      </button></div>
      <br/>

      {enableButton ? <><br/><br/><br/><div  className=" DetailsTables flex items-center justify-center"> {modelLoadError ?  <h3 className='text-red-600'> {modelLoadError}</h3>: <ClipLoader size={100}></ClipLoader> }</div></>:
      <table className=" DetailsTables table-auto border-separate border-spacing-2 border border-slate-400 ...">
        <thead>
          <tr key="header">
            <th className="bg-red-100 border text-left px-8 py-4">Name</th>
            <th className="bg-red-100 border text-left px-8 py-4">Status</th>
            <th className="bg-red-100 border text-left px-8 py-4">Parent Folder</th>
            <th className="bg-red-100 border text-left px-8 py-4">Created By</th>
            <th className="bg-red-100 border text-left px-8 py-4">Last Modified</th>
            <th className="bg-red-100 border text-left px-8 py-4">Revit Version</th>
          

          </tr>
        </thead>
        <tbody>
          {

            [...models,...modelsLoading].map(model => {
              return ( <>{model.name === "" ? <></>:
                <tr key={model.id}>
                  <td>
                    {model.failed ? <>Failed to create</> :
                    <>{model.loading ? <>
                   
                    <a>{model.name}</a> 
                    </>
                    :
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank" rel="noopener noreferrer" href={"https://acc.autodesk.com/docs/files/projects/"+project.id+"?folderUrn="+model.parentFolder.id}>{model.name}</a>
            }</>}
                    
                    </td>
                    <td>
                      {model.loading?  <Progress progress={model.progress}></Progress> : <>Created</>}
                    </td>
                  <td>{model.parentFolder.name}</td>
                  <td>{model.createUserName}</td>
                  <td>{ (new Date(model.lastModifiedTime)).toLocaleDateString()}</td>
                  <td>{model.RVTVersion}</td>
                 

                </tr>
            }</>);
            })
          }
        </tbody>
      </table>
}



      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-3xl font=semibold">Create Models</h3>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setShowModal(false)}
                  >

                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <form className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 w-full">
                    <div >
                      <label className='label-text  text-sm font-medium text-gray-700 '>
                        <input checked={MechCheck} onClick={()=>{setMechCheck(!MechCheck)}} defaultChecked={modelsCreated.Mech} disabled={modelsCreated.Mech} type='checkbox' id='MechanicalCheck' name='MechanicalCheck' />
                        <span> Mechanical {modelsCreated.Mech ? (<>(Already created)</>) : null}</span>
                      </label>
                    </div>
                    <div >
                      <label className='label-text  text-sm font-medium text-gray-700 '>
                        <input checked={ElecCheck} onClick={()=>{setElecCheck(!ElecCheck)}} defaultChecked={modelsCreated.Elec} disabled={modelsCreated.Elec} type='checkbox' id='ElectricalCheck' name='ElectricalCheck' />
                        <span> Electrical {modelsCreated.Elec ? (<>(Already created)</>) : null}</span>
                      </label>
                      <div >
                        <label className='label-text  text-sm font-medium text-gray-700 '>
                          <input checked={StructCheck} onClick={()=>{setStructCheck(!StructCheck)}} defaultChecked={modelsCreated.Struct} disabled={modelsCreated.Struct} type='checkbox' id='StructuralCheck' name='StructuralCheck' />
                          <span> Structural {modelsCreated.Struct ? (<>(Already created)</>) : null}</span>
                        </label>
                      </div>

                      <div >
                        <br></br>
                        <label className='label-text  text-sm font-medium text-gray-700 '> Model Version</label>
                        </div>
                        <div>
                        <select defaultValue={version}  onChange={(e) => {setversion(e.target.value)}} name="revitVersions" id="revitVersions">
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                        </select>
                        </div>

                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="text-white bg-green-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={creationRequested}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}




    
    </>
  )
}

