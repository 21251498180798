import axios, { AxiosResponse } from 'axios'
import { environment } from '../../environments/environment';


const BACKEND_URL = environment.baseApiUrl

export default class ProjectHelper{

    constructor(){}

    async getProjects(token:string,url?:string) : Promise<AxiosResponse["data"]>{
        if(!url){
            url=BACKEND_URL+"/ACC/Projects?sort=createdAt desc"
        }
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        let resp = await axios.get(url, options)
        
       return resp.data
        
    }

    async getTemplates(token:string){
        
        let url=BACKEND_URL+"/ACC/Templates"
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        let resp = await axios.get(url, options)
        
       return resp.data.results
    }

    async getProjectTypes(){
        let url=BACKEND_URL+"/ACC/ProjectTypes"
        
        var options = {withCredentials: true,}; 

        let resp = await axios.get(url, options)
    
       return resp.data.types
    }


    async postProject(token:string,body:any){

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        let email = (await axios.get(BACKEND_URL+"/ACC/Me", options)).data.email
        
       
        let url=BACKEND_URL+"/ACC/Project"
        
       
        let resp = await axios.post(url,body, options)
        
        try{
            await this.addUserToProject(token,resp.data.id,email)
        }catch(e){}
        
       return resp.data.id
       
    }

    async postCreateProjectType(token:string,Name:string){
    
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        
       
        let url=BACKEND_URL+"/ACC/ProjectTypes"
        
        await axios.post(url,{Name:Name}, options)

       
    }

   
    async addUserToProject(token:string,project_id:string,email:string): Promise<string>{
        let url=BACKEND_URL+"/ACC/Users/"+project_id


       
        const products =[
            {
                key:"projectAdministration",
                access: "administrator"
            },
            {
                key:"docs",
                access: "administrator"
            }
        ]
        const reqBody = {
            email:email,
            products:products
        }

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        
        return (await axios.post(url,reqBody, options)).data
    }



}