import axios from 'axios'
import { environment } from '../../environments/environment';
import { RevitModel,RevitWorkItemStatus } from '../../models/RevitModels.model';

const BACKEND_URL = environment.baseApiUrl

const Average_elec_runtime = 300 
const Average_struct_runtime = 300 
const Average_mech_runtime = 2100


export default class ModelsHelper{


    ModelsLoading : RevitModel[];

    constructor(){
        this.ModelsLoading = []
    }

    populateCreatedModels(models: RevitModel[]) : {Mech: boolean, Elec: boolean, Struct: boolean}{
        let created: {Mech: boolean, Elec: boolean, Struct: boolean} = {Mech: false, Elec: false, Struct: false}
        models.forEach((mod)=>{
            if(mod.parentFolder.name.toLowerCase().includes('mech')){
                created.Mech = true;
            }
            if(mod.parentFolder.name.toLowerCase().includes('elec')){
                created.Elec = true;
            }
            if(mod.parentFolder.name.toLowerCase().includes('struct')){
                created.Struct = true;
            }
        });
        return created;
    }

    async getPModels(token:string,projName:string,projId:string) : Promise<RevitModel[] | string>{
        
       
        let url=BACKEND_URL+"/ACC/Models/"+projName
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        let resp: any
        try{
            resp = (await axios.get(url, options)).data.data.elementGroupsByProject
        }catch(err){
            return "You must be a member of this project to view and create models!"
        }
        
        
       return this.append_Meta_Data(token,resp.results,projId)
        
    }

    async getModelsDataManagment(token:string,projName:string,projId:string) : Promise<RevitModel[] | string>{
        let url=BACKEND_URL+"/ACC/Models/"+projId+"/"+projName
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        let resp: any
        try{
            resp = (await axios.get(url, options))
        }catch(err){
            return "You must be a member of this project to view and create models!"
        }
       
        console.log(resp.data)
       return this.append_Versions_Easy(token,resp.data.included,projId)

    }

    async append_Versions_Easy(token:string,Models:any,projId:string) : Promise<RevitModel[]>{

        let urlOrig=BACKEND_URL+"/ACC/Models/Versions/"
        let folderURL =BACKEND_URL+"/ACC/Folders/Parent/"+projId+"/"

        let newModels : RevitModel[] = []
        if(!Models){return []}

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        console.log(Models.length)
        for(const model of Models){
           
            let url = urlOrig+model.relationships.derivatives.data.id
            let versionRequest = (await axios.get(url, options))

            let tempModel = model.attributes
            tempModel.RVTVersion  = versionRequest.data.derivatives[0].properties['Document Information'].RVTVersion
            
            
            let url2 = folderURL+model.relationships.item.data.id
            let folderRequest  = (await axios.get(url2, options))

            tempModel.parentFolder = {name:folderRequest.data.data.attributes.name,id:folderRequest.data.data.id}


            newModels.push(tempModel)
        }
       
       return newModels

    }

    async append_Meta_Data(token:string,Models:RevitModel[],project_id:string) : Promise<RevitModel[]>{

        let urlOrig=BACKEND_URL+"/ACC/Models/Versions/"

        
        if(!Models){return []}

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        
        for(const model of Models){
            console.log(model)
            let url = urlOrig+model.alternativeIdentifiers.fileUrn+'/b.'+project_id+'/'+model.id
            let versionRequest = (await axios.get(url, options))
            model.RVTVersion = versionRequest.data.derivatives[0].properties['Document Information'].RVTVersion
            
            console.log("here")
        }
       
       return Models

    }


    async createModels(Mech:boolean,Elec:boolean,Struct:boolean,token:string,projId:string,version:string,project_number:string){
        let url=BACKEND_URL+"/ACC/Models/Create/"+projId

        console.log("url",BACKEND_URL)
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        let data = {
            models:{Mech:Mech,Elec:Elec,Struct:Struct},
            version:version,
            project_number:project_number
        }
        let resp = (await axios.post(url,data, options)).data
        
       return resp
    }

    initializeProgress(Mech:boolean,Elec:boolean,Struct:boolean,version:string,project_number:string,WRKItemID:string): RevitModel[]{
      
      let baseName = ".rvt";
      switch(version){
        case '2023':
          baseName = "_R23"+baseName;
          break;
        case '2022':
          baseName = "_R22"+baseName;
          break;
        case '2024':
        baseName = "_R24"+baseName;
        break;
        default :
          baseName = "_R25"+baseName;
          break;
      }
      let totalWait = 0;
      if(Mech){totalWait+= Average_mech_runtime}
      if(Elec){totalWait+=Average_elec_runtime}
      if(Struct){totalWait+=Average_struct_runtime}

      if(Mech){
        
        let MechModel = { name: project_number + "_MECH"+baseName, id: "", parentFolder: { name: "40-Mech",id:"" }, createTime: "", createUserName:"", lastModifiedTime: "", version: { versionNumber: 0 },alternativeIdentifiers:{fileUrn:"",fileVersionUrn:""}, loading:true,progress:0,LoadingTime:totalWait,workItemId:WRKItemID,failed:false,RVTVersion:"",project:"",projectId:""}
        this.ModelsLoading = [...this.ModelsLoading,MechModel]
      }
      if(Elec){
        let ElecModel = { name: project_number + "_ELEC"+baseName, id: "", parentFolder: { name: "20-Elec",id:"" }, createTime: "",createUserName:"", lastModifiedTime: "", version: { versionNumber: 0 },alternativeIdentifiers:{fileUrn:"",fileVersionUrn:""}, loading:true,progress:0,LoadingTime:totalWait,workItemId:WRKItemID,failed:false,RVTVersion:"",project:"",projectId:""}
        this.ModelsLoading = [...this.ModelsLoading,ElecModel]
      }
      if(Struct){
        let StructModel = { name: project_number + "_STRUCT"+baseName, id: "", parentFolder: { name: "10-Struct",id:"" }, createTime: "",createUserName:"", lastModifiedTime: "", version: { versionNumber: 0 },alternativeIdentifiers:{fileUrn:"",fileVersionUrn:""}, loading:true,progress:0,LoadingTime:totalWait,workItemId:WRKItemID,failed:false,RVTVersion:"",project:"",projectId:""}
        this.ModelsLoading = [...this.ModelsLoading,StructModel]
      }

      return this.ModelsLoading
    }

    async CheckModels(projId:string,token:string) : Promise<boolean>{
        let newModels : RevitModel[] = []
        for(let mod of this.ModelsLoading){
            if(mod.loading){
                let result = await this.CheckStatus(mod,projId,token)
                if(result.status !== "inprogress" && result.status !== "pending" && result.status !== "success"){
                    mod.failed = true
                    mod.loading = false
                }else if(result.status === "success"){
                    setTimeout(()=>{},30000)
                    return true
                   // mod = result.model
                   // mod.loading = false
                }else{
                    let timeStarted = new Date(result.stats.timeQueued)
                    
                    var timeDifference = new Date().getTime() - timeStarted.getTime();

                    timeDifference = timeDifference/1000


                    if(timeDifference > mod.LoadingTime ){
                        mod.progress = 99
                    }else{
                        
                        mod.progress = Number(((timeDifference/mod.LoadingTime)*100).toFixed(0))
                    }
                    console.log(timeDifference)
                    console.log((timeDifference/mod.LoadingTime))
                }
            }
            newModels.push(mod)
           
        }
        this.ModelsLoading = newModels
        return false
      }

      async CheckStatus(model:RevitModel,projId:string,token:string) : Promise<RevitWorkItemStatus>{


            
        let url=BACKEND_URL+"/getWorkItem/"+model.workItemId
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        let resp = (await axios.get(url, options)).data

        console.log("resp",resp)
        if(resp && resp.status === "inprogress"){

            
            url = BACKEND_URL+"/ACC/Models/"+projId

            let allModels = (await axios.get(url, options)).data.data.elementGroupsByProject
            if(allModels.length > 0){
                for(let mod of allModels){
                    if(mod.name === model.name){
                        resp.status = "success"
                        resp.model = mod
                    }
                }
            }
           
        }
        return resp

      }
}