import React, { useState, useEffect, useRef } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import './AppBundles.css'
import AppBundleHelper from './AppBundleHelper';
import { Activities,AppBundleRequest } from '../../models/AppBundle.model';
import { bindCallback } from 'rxjs';


type inpParams = { token: string }
export default function AppBundles({ token }: inpParams) {


    const [engineHealth, setEngineHealth] = useState("")
    const [activities, setActivities] = useState<Array<Activities>>([])
    const [ShowAddActivityModal, setShowAddActivityModal] = useState(false)
    const [engines,setEngines] = useState<Array<string>>([])
    const effectRan = useRef(false);
    const [activitiesLoading, setActivitiesLoading] = useState(true)
    const [creatingAPpBundle,setCreatingAppBundle] = useState(false)

    let helper = new AppBundleHelper();


    useEffect(() => {

        if (!effectRan.current) {

            const asyncfunc = async () => {
                var titleElement = window.document.getElementById("TitleNav")
                if (titleElement) {
                    titleElement.innerText = "Activities and Bundles"
                }

                setActivities(await helper.getActivities(token))
                setEngineHealth(await helper.getEngineHealth(token))
                setEngines(await helper.getEngines(token))
                setActivitiesLoading(false);

            }

            asyncfunc()

        }

        return () => { effectRan.current = true };

    }, [])

    async function deleteActivity(ActivityId: string, BundleID:string) {
    //   window.alert("Nice try!")
      ActivityId = ActivityId.split('.')[1].split('+')[0]
      BundleID = BundleID.split('.')[1].split('+')[0]

  //return
      await helper.DeleteActivity(ActivityId,token)
      await helper.DeleteBundle(BundleID,token)
     // window.location.reload()
    }

    

    
    const submitAppBundle = () => {

       
        let form = window.document.getElementById("createAppBundle") as HTMLFormElement
        
        
        if(form ){

            let data = new FormData(form);
          
           
          setCreatingAppBundle(true);

          let bundleid = data.get("bundleid")?.toString()
          if(!bundleid){return}
          let engine = data.get("engine")?.toString()
          if(!engine){return}
          let description = data.get("description")?.toString()
          if(!description){return}
          let alias = data.get("alias")?.toString()
          if(!alias){return}
          let version = data.get("version")?.toString()
          if(!version){return}

          let code = (data.get("code") as File)

      
          
         
          let body = new AppBundleRequest(bundleid,engine,description,(code as File),alias,version );

          
          console.log(body);

          
          helper.postAppBundle(token,body).then(()=>{

            setCreatingAppBundle(false);
            setShowAddActivityModal(false);
              setTimeout(()=>{},3000);
              form.reset()

          })
              
         
            
        } 
      
        
    }

    function readFile(event : any) {
       
        console.log(event.target.result);
      }


    return (
        <>

            <a className='DetailsHeaders'> If you don't know what this is, don't touch it! (Contact mason.lumley@pec1.com for any questions)</a>
            <p className='DetailsPargraph'><b>App Bundles:</b> Contains an add-in file that can run in the cloud. You can define and App Bundle by versions and aliases to show
                either an iteration of the app bundle or what system it is meant for (ex. prod vs dev, v1 vs v2). Since we simply use this for project setup, the user can only create one app Bundle
                and cannot post differnt versions or aliases.
                <br></br>
                <b>Activities:</b> the process that runs an app bundle. Defined by an app bundle and an enginer (in this case). This is what we can actually call in the cloud to
                set up projects. This is also connected to an app bundle and cannot be versioned and with this applicaiton, a app bundle can only be tied to one activity. Again,
                this can be changed if need be.
            </p>

{activitiesLoading ? <><br/><br/><br/><br/><div  className=" DetailsTables flex items-center justify-center">  <ClipLoader size={100}></ClipLoader> </div></> : 
<>
            <div className='ActionsHead'>
                <a className='DetailsHeaders'> Revit Engine Health:&emsp;  {engineHealth}</a>
                <button
                    className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowAddActivityModal(true)}>
                    Add Activity/Bundle
                </button>
            </div>
            <div className='tableContainer w-11/12 flex justify-center'>

                {
                    //https://aps.autodesk.com/en/docs/design-automation/v3/reference/http/activities-id-GET/
                }
                <table className="shadow-lg bg-white  ">
                    <thead>
                        <tr>

                            <th className="bg-red-100 border text-left px-8 py-4">Id</th>
                            <th className="bg-red-100 border text-left px-8 py-4">Engine</th>
                            <th className="bg-red-100 border text-left px-8 py-4">Description</th>
                            <th className="bg-red-100 border text-left px-8 py-4">Bundle</th>
                            <th className="bg-red-100 border text-left px-8 py-4">Delete</th>

                        </tr>
                    </thead>
                    <tbody>
                        {activities.map(item => {
                            return (
                                <tr key={item.id}>

                                    <td>{item.id}</td>
                                    <td>{item.engine}</td>
                                    <td>{item.description}</td>
                                    <td>{item.appbundles ? item.appbundles[0] : null}
                                    </td>
                                    <td><button onClick={()=>{deleteActivity(item.id,(item.appbundles[0] as string) || "")}} className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                        </svg></button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>


            {ShowAddActivityModal ? (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative w-auto my-6 mx-auto max-w-6xl">
                            <div className="border-4 rounded-2xl shadow-lg relative flex flex-col min-w-max bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                    <h3 className="text-3xl font=semibold">Add Activity/Bundle</h3>
                                    <button
                                        className="bg-transparent border-0 text-black float-right"
                                        onClick={() => setShowAddActivityModal(false)}
                                    >

                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                    {!creatingAPpBundle ? 
                                <form id="createAppBundle" className=" shadow-md rounded px-8 pt-6 pb-8 w-full">
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="bundleid">
                                                Bundle Id
                                            </label>
                                            <input type='text' placeholder='Bundle Id' className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="bundleid" name="bundleid" />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full md:full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="engine">
                                                    Engine
                                                </label>
                                                <div className="relative">
                                                    <select defaultValue="" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="engine" name="engine" required>
                                                      <option value="" disabled={true}>Engine</option>
                                                      {engines.map(item=>{return(<option >{item}</option>)})}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>


                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="description">
                                                Description
                                            </label>
                                            <input type='text' placeholder='Description' className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="description" name="description" />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="alias">
                                                Alias (Keeping it default as "latest" is fine)
                                            </label>
                                            <input type='text' defaultValue="latest" placeholder='Bundle Id' className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="alias" name="alias" />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="version">
                                                Version (Keeping it default at "1" is fine)
                                            </label>
                                            <input type='number' defaultValue={1} placeholder='Bundle Id' className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="version" name="version" />
                                        </div>
                                    </div>

                                    
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="code">
                                                Code
                                            </label>
                                            <input type='file' placeholder='Code' className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="code" name="code" />
                                        </div>
                                    </div>


</form> : <><br/><br/><br/><br/><div  className=" DetailsTables flex items-center justify-center">  <ClipLoader size={100}></ClipLoader> </div></> }

                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={() => setShowAddActivityModal(false)}
                                    >
                                        Close
                                    </button>

                                    <button
                                        className="text-white bg-green-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={submitAppBundle}
                                    >
                                        Submit
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            </>
            }

        </>

    );
}